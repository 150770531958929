.Page404 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
}

@media screen and (min-width: 600px) {
	.Page404 figure {
		max-width: 25vw;
		max-height: 25vw;
		aspect-ratio: initial;
	}
}

@media screen and (max-width: 600px) {
	.Page404 figure {
		max-width: 100vw;
		max-height: 100vw;
		aspect-ratio: initial;
		display: block;
	}

	.Page404 figure img {
		max-width: 100%;
		max-height: 100%;
	}
}