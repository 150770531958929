.logo {
	aspect-ratio: 1;
	max-width: 50px;
	padding: 5px 0 5px 5px;
}

.sharedLayout {
	min-height: 100vh;
}

.sharedLayout nav {
	padding-top: 0;
}

article {
	display: flex;
	flex-flow: column;
	text-align: justify;
}

article h1, h2, h3, h4, h5, h6 {
	color: powderblue;
}

.mainContentDiv {
	min-height: 90vh;
}

footer {
	border-top: lightgrey solid 1px;
	margin-top: auto;
	margin-right: 0;
}

footer * {
	max-width: 100%;
}

.sharedLayout footer tr th {
	padding-bottom: 0;
}

footer tbody {
	margin: 0;
}

footer tbody td {
	margin: 0;
}

footer td {
	font-size: smaller;
}

figure {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
}

figure figcaption {
	font-style: italic;
	font-size: smaller;
}

@media screen and (min-width: 600px) {
	article {
		padding: 20px 20% 20px 20%;
	}

	article p:first-of-type {
		text-indent: 20px;
	}

	article figure {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}

	article img {
		display: block;
		max-width: 60vw;
		max-height: 80vh;
		margin-left: auto;
		margin-right: auto;
		aspect-ratio: initial;
	}
}

@media screen and (max-width: 600px) {
	article figure.figure-decorative {
		display: none;
	}

	article figure.figure-essential {
		max-width: 100vw;
		max-height: 100vh;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	article figure img {
		max-width: 100%;
		max-height: 100%;
	}

	article {
		padding: 0 5% 10px 5%;
	}
}